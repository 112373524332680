import SlimSelect from 'slim-select'

document.addEventListener  'turbolinks:load', () ->
  selects = document.querySelectorAll 'select[multiple="multiple"]'
  for select in selects
    new SlimSelect({
      select: select
      placeholder: 'Seleccione opción'
      hideSelectedOption: true
      searchPlaceholder: 'Buscar'
      searchText: 'No se encontraron resultados'
      searchHighlight: true
      allowDeselectOption: true
      allowDeselect: true
    })
